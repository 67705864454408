<template>
  <form class="uk-form-stacked" v-on:submit.prevent="submit(name, description, key_type)">
    <label class="uk-flex uk-flex-column uk-margin-bottom">
      <span>name<span class="uk-text-muted"> {{ error.name }}</span></span>
      <div class="uk-form-controls uk-inline">
          <i class="uk-form-icon" uk-icon="icon: database"></i>
          <input
            class="uk-input"
            type="text"
            v-model="name"
            pattern="[a-z](?:_?[a-z0-9]+)*"
            placeholder="enter the unique project name"
            autofocus
            required
            v-bind:disabled="active"
            spellcheck="false"
            autocorrect="off"
            autocapitalize="off"
            autocomplete="off">
      </div>
      <p class="uk-margin-large-left uk-margin-small-top uk-text-right uk-text-meta
            uk-margin-remove-bottom uk-animation-fade">
        Enter a short, memorable name for the project. This name is used to generate
        URLs and must be used to connect to spreadsheets, so short and simple is best.
        The name must start with a lowercase letter
        ("a" to "z"), and can contain lowercase letters, numbers ("0" to "9"), with
        an underscore ("_") between words.
      </p>
    </label>

    <label class="uk-flex uk-flex-column uk-margin-bottom">
      <span>key type<span class="uk-text-muted"> {{ error.key_type }}</span></span>
      <select class="uk-form-controls uk-inline uk-select" v-model="key_type" v-bind:disabled="active">
          <option value="" disabled>-- select default primary key --</option>
          <option value="INT">32-bit integer</option>
          <option value="BIGINT">64-bit integer</option>
          <option value="TIMEUUID">Version 1 UUID</option>
      </select>
      <p class="uk-margin-large-left uk-margin-small-top uk-text-right uk-text-meta
            uk-margin-remove-bottom uk-animation-fade" v-if="help">
        Select the default primary key type for database tables in this project.
      </p>
    </label>

    <label class="uk-flex uk-flex-column uk-margin-bottom">
      <span>description<span class="uk-text-muted"> {{ error.description }}</span></span>
      <textarea
        class="uk-form-controls uk-inline
                uk-textarea uk-height-small"
        style="resize: vertical"
        v-model="description"
        placeholder="describe the contents of this project"
        required
        v-bind:disabled="active">
      </textarea>
      <p class="uk-margin-large-left uk-margin-small-top uk-text-right uk-text-meta
            uk-margin-remove-bottom uk-animation-fade" v-if="help">
        Enter a brief summary of the intended contents of the project. This summary
        helps your contacts understand what data they are going to find in the project,
        as well as helping them be sure they have connected to the right location.
      </p>
    </label>

    <SubmitFooter
      :error="error.msg"
      :active="active"
      label="setup"
      cancel="cancel"
      v-on:cancel="$emit('cancel')">
    </SubmitFooter>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import CustomerService from '@/sdk/consumer.js';
import SubmitFooter from '@/components/SubmitFooter.vue';

export default {
  name: 'FormSetup',
  components: {
    SubmitFooter,
  },
  props: {
    wiki: String,
    links: CustomerService
  },
  data: function initialData() {
    return {
      active: false,
      name: null,
      description: null,
      key_type: '',
      error: {
        msg: null,
        name: null,
        key_type: null,
        description: null,
      },
    };
  },
  methods: {
    async submit(name, description, key_type) {
      this.active = true;
      this.error = {
        msg: null,
        name: null,
        key_type: null,
        description: null,
      };
      try {
          await this.links.asSetupProjectUri().postAsync(name, description, key_type);
          this.$emit('ok');
      } catch (err) {
        this.error.msg = err.message ?? 'An unexpected error occurred';
        if (!err.status) {
          console.error(err);
        } else {
          var tree = this.$error(err.status, err.error);
          this.error.name = tree.claim('/name');
          this.error.key_type = tree.claim('/key_type');
          this.error.description = tree.claim('/description');
          if (tree.isCausedBy('data.5600')) {
            this.error.name = 'already exists';
          } else if (tree.assert_claimed()) {
            this.error.msg = 'the form cannot display an important error';
          }
        }
      } finally {
          this.active = false;
      }
    },
  },
  computed: {
    ...mapState('api', ['help']),
  },
};
</script>

<template>
  <Lock :wiki="wiki">
    <article class="uk-height-viewport uk-flex uk-flex-column uk-flex-middle">
      <SiteHeader class="uk-margin-top"></SiteHeader>
      <ProjectSearch v-if="session" :wiki="wiki" class="uk-width-xlarge uk-padding"></ProjectSearch>
      <div class="uk-flex-1"></div>
      <SiteFooter class="uk-flex-none uk-flex-bottom"></SiteFooter>
    </article>
  </Lock>
</template>

<script>
import { mapState } from 'vuex';

import Lock from '@/components/Lock.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import SiteFooter from '@/components/SiteFooter.vue';
import ProjectSearch from '@/components/ProjectSearch.vue';

export default {
  name: 'Projects',
  props: {
    wiki: String,
  },
  components: {
    Lock,
    SiteHeader,
    SiteFooter,
    ProjectSearch,
  },
  methods: {
  },
  computed: {
    ...mapState('api', ['help', 'session']),
  },
}
</script>
<template>
    <section >
        <ul class="uk-breadcrumb uk-flex-left">
            <li><router-link to="/home">home</router-link></li>
            <li><span>{{ wiki }}</span></li>
        </ul>
        <form
            class="uk-width-expand uk-flex uk-flex-column uk-form-stacked uk-text-left"
            v-on:submit.prevent="debouncedSearch()">

            <label class="uk-flex uk-flex-column uk-margin-bottom">
            <span>{{ $label('project.search') }}<span class="uk-text-muted"> {{ error.search }}</span></span>
            <div class="uk-button-group">
                <div class="uk-form-controls uk-inline uk-width-expand">
                    <i class="uk-form-icon" uk-icon="icon: search"></i>
                    <input
                        class="uk-input"
                        type="text"
                        v-model="search"
                        :placeholder="$command('project.search')"
                        autofocus>
                </div>
                <button class="uk-button uk-button-default uk-button-small"
                    uk-icon="plus"
                    v-on:click="showSetup()"
                    :disabled="!session.hasSetupProject">
                </button>
            </div>
            <p class="uk-margin-large-left uk-margin-small-top uk-text-right uk-text-meta
                        uk-margin-remove-bottom uk-animation-fade" v-if="help">
                {{ $help('project.search') }}
            </p>
            </label>
        </form>
        <div ref="setup" uk-modal>
            <div class="uk-modal-dialog">
                <div class="uk-modal-body">
                <h2 class="uk-modal-title">configure project</h2>
                <p class="uk-margin-large-left uk-text-right">
                    Select a name for the project, write a short summary of the
                    intended contents and use of the project, and we'll get you started.
                </p>
                <FormSetup :wiki="wiki" :key="key" :links="session" v-on:ok="setupComplete()"></FormSetup>
                </div>
            </div>
        </div>
        <ul class="uk-list uk-margin-remove-top">
            <li  v-for="(item, index) in projects" :key="index">
                <article class="uk-comment">
                    <header class="uk-comment-header uk-grid-medium uk-flex-middle uk-margin-small-bottom" uk-grid>
                        <div class="uk-width-expand">
                            <h4 class="uk-comment-title uk-margin-remove">{{ item.name }}</h4>
                            <ul class="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top">
                                <li><a :href="item.url">Wiki</a></li>
                                <li><router-link :to="{ name: 'canvas', params: { path: item.path }}">Design</router-link></li>
                                <li v-if="item.service.hasModule"><router-link :to="{ name: 'project', params: { path: item.path }}"><i uk-icon="cog"></i></router-link></li>
                            </ul>
                        </div>
                    </header>
                    <div class="uk-comment-body">
                    <p>{{ item.description }}</p>
                    </div>
                </article>
            </li>
        </ul>

        <div class="uk-text-muted uk-flex uk-flex-center uk-flex-middle uk-margin-bottom"
            v-if="error.msg">
        <span class="uk-text-meta uk-text-right">
            {{ error.msg }}
        </span>
        <i class="uk-margin-small-left" uk-icon="icon: warning; ratio: 1"></i>
        </div>
        <div class="uk-text-muted uk-flex uk-flex-center uk-flex-middle"
            v-else-if="active || searchIsDirty">
        <span class="uk-margin-small-right" uk-spinner="ratio: 1"></span>
        <span class="uk-text-meta uk-text-right">
            {{ projects.length ? 'searching projects' : 'loading projects' }}...
        </span>
        </div>
        <div class="uk-text-meta uk-text-center uk-margin-remove-top"
            v-else-if="!projects.length">
            <i class="uk-icon" uk-icon="icon: info"></i>
            no projects found
        </div>
        <footer class="uk-flex uk-flex-center">
            <button class="uk-button uk-button-default uk-border-pill "
                v-if="this.results && this.results.hasNext && !active && !searchIsDirty"
                v-on:click="loadProjects()">
            load more
            </button>
        </footer>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import { modal } from 'uikit';

import FormSetup from '@/components/FormSetup.vue';

export function debounce(fn, delay, ...args) {
  let timeoutID = null;
  return function schedule() {
    clearTimeout(timeoutID);
    const that = this;
    timeoutID = setTimeout(() => fn.apply(that, args), delay);
  };
}

export default {
    name: 'ProjectSearch',
    components: {
        FormSetup,
    },
    props: {
        wiki: String,
    },
    data() {
        return {
            searchIsDirty: true,
            active: false,
            search: null,
            results: null,
            projects: [],
            error: {
                search: null,
                msg: null,
            },
            key: 0,
        };
    },
    mounted() {
        this.debouncedSearch();
    },
    methods: {
        showSetup() {
            modal(this.$refs.setup).show();
        },
        debouncedSearch: debounce(function offsetSearch() {
            if (this.searchIsDirty && !this.active) {
                this.projects = [];
                this.results = null;
                this.searchIsDirty = false;
                if (this.session.hasListModules) {
                    this.error = {
                        search: null,
                        msg: null,
                    };
                    this.loadProjects();
                } else {
                    this.error = {
                        search: null,
                        msg: "the project list is unavailable",
                    };
                }
            } else if (this.searchIsDirty) {
                this.debouncedSearch();
            }
        }, 460),
        async loadProjects() {
            if (this.results && this.results.hasNext) {
                this.active = true;
                try {
                    await this.results.loadMoreAsync();
                    this.projects.push.apply(this.projects, this.results.rows);
                } catch (err) {
                    this.error.msg = err.message ?? 'An unexpected error occurred';
                } finally {
                    this.active = false;
                }
            } else if (!this.results) {
                this.active = true;
                try {
                    this.results = await this.session.asListModulesUri()
                        .withParams(this.search)
                        .getAsync()
                        .then(results => results.map(x => { return {...x, service: this.session.from(x)}}));
                    this.projects.push.apply(this.projects, this.results.rows);
                } catch (err) {
                    this.error.msg = err.message ?? 'An unexpected error occurred';
                    if (!err.status) {
                        console.error(err);
                    } else {
                        var tree = this.$error(err.status, err.error);
                        this.error.search = tree.claim('/search');
                        if (tree.assert_claimed()) {
                            this.error.msg = 'the form cannot display an important error';
                        }
                    }
                } finally {
                    this.active = false;
                }
            }
        },
        setupComplete: function closeModal() {
            try {
                modal(this.$refs.setup).hide();
                this.key += 1;
            } finally {
                this.search = null;
                this.searchIsDirty = true;
                this.debouncedSearch();
            }
        },
    },
    computed: {
        ...mapState('api', ['help', 'session']),
    },
    watch: {
        search() {
            this.searchIsDirty = true;
            this.debouncedSearch();
        },
    }
}
</script>